// ** MUI Imports
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Plus from 'mdi-material-ui/Plus'
import Chip from '@mui/material/Chip'
import ContentSave from 'mdi-material-ui/ContentSave'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { searchChinahpo } from '@/@core/services/app.service'
import { useTranslation } from 'react-i18next'
import ChinahpoSearch from '@/@core/components/chinahpo-search'
import {
  GetTermsByClinicalInfoDto,
  translate,
  translates,
  getTermByClinicalInfo,
  TranslateBulkDto,
  TranslateDto
} from '@/@core/services/app.service'
import { unique } from '@/@core/utils/utils'

interface Props {
  clinical: {
    termList: any[]
    clinicalInformation?: string
  }
  fn?: any
}

const StepClinical = (props: Props) => {
  const { t } = useTranslation()
  const [sampleField, setSampleField] = React.useState({
    ...props
  })

  const translateValue = (value: string[]) => {
    const obj = {} as any
    const sourceText = value.map((item, index) => {
      obj[index] = item
    })

    const sourceLanguage = 'zh',
      targetLanguage = 'en'

    const params: TranslateBulkDto = {
      sourceText: obj,
      sourceLanguage,
      targetLanguage
    }

    Translates.mutateAsync(params)
  }

  const Translate = useMutation({
    mutationFn: async (data: TranslateDto) => await translate(data),
    onSuccess: (data, variables, context) => {
      //post返回的结果
    }
  })

  const Translates = useMutation({
    mutationFn: async (data: TranslateBulkDto) => await translates(data),
    onSuccess: (data: { translated: string; wordCount: string }[], params) => {
      //post返回的结果

      if (params.sourceLanguage === 'zh')
        GetTermByClinicalInfo.mutateAsync({
          clinicalInfo: data
            .map(
              (str) =>
                str.translated.charAt(0).toUpperCase() + str.translated.slice(1)
            )
            .join(',')
        })
    }
  })

  const GetTermByClinicalInfo = useMutation({
    mutationFn: async (data: GetTermsByClinicalInfoDto) =>
      await getTermByClinicalInfo(data),
    onSuccess: async (data: { terms: string[] }) => {
      //post返回的结果

      const sourceLanguage = 'en',
        targetLanguage = 'zh'

      const terms = await Promise.all(
        data.terms.map(async (item) => {
          const params: TranslateDto = {
            sourceText: item,
            sourceLanguage,
            targetLanguage
          }

          const data = await Translate.mutateAsync(params)

          return {
            definition_cn: '',
            definition_en: '',
            hpoId: '',
            name_cn: data,
            name_en: item,
            altTitle: '',
            cnTitle: '',
            incTitle: '',
            mimNumber: 0,
            preTitle: data,
            prefix: '',
            title: `${item}(${data})`
          }
        })
      )

      props.fn('clinical', {
        ...sampleField.clinical,
        termList: unique(
          [...sampleField.clinical.termList, ...terms],
          'name_en'
        )
      })

      setSampleField({
        clinical: {
          ...sampleField.clinical,
          termList: unique(
            [...sampleField.clinical.termList, ...terms],
            'name_en'
          )
        }
      })
    }
  })

  return (
    <Grid container pt={4}>
      <Grid container>
        <Grid item xs={12} padding={'5px 0'}>
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 1
            }}
            padding={'5px 0'}
          >
            <Grid item>
              <Button
                startIcon={<Plus />}
                size="small"
                variant="contained"
                onClick={() => {
                  if (sampleField.clinical.clinicalInformation as string)
                    sampleField.clinical.clinicalInformation &&
                      translateValue(
                        sampleField.clinical.clinicalInformation.split('，')
                      )
                }}
              >
                {t('apply')}
              </Button>
            </Grid>
          </Grid>

          <TextField
            label={t('Clinical information')}
            fullWidth
            id="outlined-multiline-flexible"
            multiline
            maxRows={10}
            minRows={4}
            value={sampleField.clinical.clinicalInformation}
            onChange={(e) => {
              setSampleField({
                clinical: {
                  ...sampleField.clinical,
                  clinicalInformation: e.target.value
                }
              })

              props.fn('clinical', {
                ...sampleField.clinical,
                clinicalInformation: e.target.value
              })
            }}
            placeholder={t(
              'Please enter and click the Apply button in the upper right corner'
            )}
          />
        </Grid>

        <Grid item xs={12} paddingBottom={'20px'}>
          <ChinahpoSearch
            termList={sampleField.clinical.termList}
            fn={(query) => {
              props.fn('clinical', {
                ...sampleField.clinical,
                termList: query
              })

              setSampleField({
                clinical: {
                  ...sampleField.clinical,
                  termList: query
                }
              })

              return query
            }}
            sx={{
              width: '100%',
              minWidth: 800,
              '& .MuiInputBase-root': {
                alignItems: 'flex-start'
              }
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StepClinical
