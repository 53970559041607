// ** React Imports
import { ChangeEvent, useState } from 'react'

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FileUploaderMultiple from 'src/views/forms/file-uploader/FileUploaderMultiple'
import { DropzoneWrapper } from './DropzoneStyle'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'

// ** Type Imports
import {
  CustomRadioIconsData,
  CustomRadioIconsProps
} from 'src/@core/components/custom-radio/types'

// ** Custom Components Imports
import CustomChip from 'src/@core/components/mui/chip'
import CustomRadioIcons from 'src/@core/components/custom-radio/icons'
import Card from '@mui/material/Card'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  createGenePanel,
  createGeneSets,
  createProject,
  editGenePanel,
  editGeneSets,
  fetchProjects,
  GenePanel,
  GeneSets,
  getGenePanel,
  getGeneSets,
  Project,
  updateProject,
  upload
} from '@/@core/services/app.service'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import Translations from '@/layouts/components/Translations'
import PositionedSnackbar from '@/@core/components/message'
import RolDialog from '@/views/setting/DialogRol'
import PanelDialog from '@/views/setting/DialogPanel'
import ProjectDialog from '../DialogProject'
import { Autocomplete, createFilterOptions, Typography } from '@mui/material'
import { isWindow } from '@/@core/utils/utils'

interface IconType {
  icon: CustomRadioIconsProps['icon']
  iconProps: CustomRadioIconsProps['iconProps']
}

interface FilmOptionType {
  inputValue?: string
  id?: number
  name: string
}
const filter = createFilterOptions<FilmOptionType>()

interface Props {
  sample: {
    sampleName?: string
    sampleSource?: string
    type?: string
    genomeBuild?: null | 'hg19' | 'hg38'
    projectId?: number | string[] | null
    genePanel?: string | number | string[] | null
    targetRegionId?: number[] | number | null
    prefix?: string
    fileSize?: number
  }
  fn?: any
  control: any
  errors: any
  setValue?: any
  sampleType?: string
}

const StepSample = React.forwardRef((props: Props, ref) => {
  const { t } = useTranslation()
  const fileType = props.sample.sampleName ?? '.vcf'
  const sampleType = props.sampleType

  const data: CustomRadioIconsData[] = [
    {
      isSelected: !(sampleType === 'fastq' || sampleType === 'naf'),
      value: 'wes',
      title: 'WES'
    },
    {
      isSelected: Boolean(sampleType === 'fastq' || sampleType === 'naf'),
      value: 'wgs',
      title: 'WGS'
    },
    {
      value: 'gene_panel',
      title: t('gene') + 'Panel'
    },
    {
      value: 'microarray',
      title: 'MicroArray'
    },
    {
      value: 'rna',
      title: 'gVCF'
    }
  ]
  const initialIconSelected: string = data.filter((item) => item.isSelected)[
    data.filter((item) => item.isSelected).length - 1
  ].value

  const regionArray = [
    'East Asia',
    'Europe',
    'Africa',
    'Australia',
    'North America',
    'South America'
  ]

  const genomeBuildArray = ['hg19', 'hg38']
  const sampleSourceArray = [
    {
      title: 'DNA',
      key: 'DNA'
    },
    {
      title: t('saliva'),
      key: 'saliva'
    },
    {
      title: t('blood'),
      key: 'blood'
    },
    {
      title: t('mouth swab'),
      key: 'mouth-swab'
    },
    {
      title: t('amniotic fluid'),
      key: 'amniotic-fluid'
    },
    {
      title: t('umbilical blood'),
      key: 'umbilical-blood'
    },
    {
      title: t('aborted tissue'),
      key: 'aborted-tissue'
    }
  ]

  // ** States
  const [region, setRegion] = useState<string[]>([])
  const [sampleField, setSampleField] = useState({
    sample: {
      ...props.sample
    }
  })

  const messageRef = React.useRef<any>()
  const [openRol, setOpenRol] = React.useState<boolean>(false)
  const [openPanel, setOpenPanel] = React.useState<boolean>(false)
  const [openProject, setOpenProject] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<FilmOptionType | null>(null)
  const [selectedRadio, setSelectedRadio] =
    useState<string>(initialIconSelected)

  // ** Hook
  const theme = useTheme()

  const icons: IconType[] = [
    {
      icon: 'mdi:tag-outline',
      iconProps: {
        fontSize: '2rem',
        style: { marginBottom: 4 },
        color: theme.palette.text.secondary
      }
    },
    {
      icon: 'mdi:tag-outline',
      iconProps: {
        fontSize: '2rem',
        style: { marginBottom: 4 },
        color: theme.palette.text.secondary
      }
    },
    {
      icon: 'mdi:tag-outline',
      iconProps: {
        fontSize: '2rem',
        style: { marginBottom: 4 },
        color: theme.palette.text.secondary
      }
    },
    {
      icon: 'mdi:tag-outline',
      iconProps: {
        fontSize: '2rem',
        style: { marginBottom: 4 },
        color: theme.palette.text.secondary
      }
    },
    {
      icon: 'mdi:tag-outline',
      iconProps: {
        fontSize: '2rem',
        style: { marginBottom: 4 },
        color: theme.palette.text.secondary
      }
    }
  ]

  const handleChange = (event: SelectChangeEvent<typeof region>) => {
    const {
      target: { value }
    } = event

    setRegion(typeof value === 'string' ? value.split(',') : value)
  }

  const handleRadioChange = (prop: string | ChangeEvent<HTMLInputElement>) => {
    if (typeof prop === 'string') {
      setSelectedRadio(prop)

      setSampleField({
        sample: {
          ...sampleField.sample,
          type: prop
        }
      })

      props.fn('sample', {
        ...sampleField.sample,
        type: prop
      })
    } else {
      setSampleField({
        sample: {
          ...sampleField.sample,
          type: (prop.target as HTMLInputElement).value
        }
      })

      props.fn('sample', {
        ...sampleField.sample,
        type: (prop.target as HTMLInputElement).value
      })

      setSelectedRadio((prop.target as HTMLInputElement).value)
    }
  }

  const { data: projects = [], refetch } = useQuery<Project[]>(
    'project',
    async () => await fetchProjects({ pageNum: 1, pageSize: 1000 }),
    {
      keepPreviousData: true
    }
  )

  const { data: genePanel, refetch: refetchPanel } = useQuery<GenePanel[]>(
    ['genePanel'],
    async () =>
      await getGenePanel({
        pageNum: 1,
        pageSize: 1000
      }),
    {
      keepPreviousData: true
    }
  )
  const { data: geneSets, refetch: refetchRol } = useQuery<GeneSets[]>(
    ['geneSets'],
    async () =>
      await getGeneSets({
        pageNum: 1,
        pageSize: 1000
      }),
    {
      keepPreviousData: true
    }
  )

  const handleSaveGeneSets = (geneSet: any) => {
    if ('name' in geneSet && 'id' in geneSet) {
      updateGeneSetsMutation.mutate(geneSet)
    } else {
      createGeneSetsMutation.mutate(geneSet)
    }
  }

  const handleSaveGenePanel = (project: any) => {
    if ('name' in project && 'id' in project) {
      editGenePanelMutation.mutate(project)
    } else {
      createGenePanelMutation.mutate(project)
    }
  }

  const handleSaveProject = (project: any) => {
    if ('name' in project && 'description' in project && 'id' in project) {
      updateProjectMutation.mutate(project)
    } else {
      createProjectMutation.mutate(project)
    }
  }

  const createProjectMutation = useMutation(createProject, {
    onSuccess: () => {
      setOpenProject(false)

      messageRef?.current?.handleClick(t('New successful'), 'success')

      refetch()
    },
    onError: (err: any) => {
      console.log(err)

      messageRef?.current?.handleClick(
        err?.response?.data?.message ?? '请重试',
        'error'
      )
    }
  })
  const updateProjectMutation = useMutation(updateProject, {
    onSuccess: () => {
      setOpenProject(false)

      messageRef?.current?.handleClick(t('Edit success'), 'success')

      refetch()
    }
  })

  const createGenePanelMutation = useMutation(createGenePanel, {
    onSuccess: () => {
      setOpenPanel(false)

      messageRef?.current?.handleClick(t('New successful'), 'success')

      refetchPanel()
    }
  })
  const editGenePanelMutation = useMutation(editGenePanel, {
    onSuccess: () => {
      setOpenPanel(false)

      messageRef?.current?.handleClick(t('Edit success'), 'success')

      refetchPanel()
    }
  })

  const createGeneSetsMutation = useMutation(createGeneSets, {
    onSuccess: () => {
      setOpenRol(false)

      refetchRol()
    }
  })
  const updateGeneSetsMutation = useMutation(editGeneSets, {
    onSuccess: () => {
      setOpenRol(false)

      refetchRol()
    }
  })

  React.useImperativeHandle(ref, () => {
    return {
      sampleField
    }
  })

  return (
    <React.Fragment>
      <Grid container spacing={5}>
        {data.map((item, index) => (
          <CustomRadioIcons
            key={index}
            data={data[index]}
            icon={icons[index].icon}
            selected={selectedRadio}
            name="custom-radios-deal"
            gridProps={{ sm: 2.4, xs: 12 }}
            handleChange={handleRadioChange}
            iconProps={icons[index].iconProps}
          />
        ))}

        <Grid item xs={12} sm={6}>
          <Box className={'flex items-center gap-2'} alignItems="baseline">
            <Typography
              className="font-medium"
              color="text.primary"
              sx={{
                wordBreak: 'keep-all',
                mr: 2
              }}
              flex={2}
              textAlign="right"
            >
              {t('Sample name')}
              <span style={{ color: 'red' }}>*</span>:
            </Typography>

            <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
              <Controller
                name="sampleName"
                control={props.control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    autoFocus
                    size={'small'}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      setSampleField({
                        sample: {
                          ...sampleField.sample,
                          sampleName: e.target.value
                        }
                      })

                      props.fn('sample', {
                        ...sampleField.sample,
                        sampleName: e.target.value
                      })

                      onChange(e)
                    }}
                    error={Boolean(props.errors.sampleName)}
                  />
                )}
              />

              {props.errors.sampleName && (
                <FormHelperText sx={{ color: 'error.main' }}>
                  {props.errors.sampleName.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={'flex items-center gap-2'} alignItems="baseline">
            <Typography
              className="font-medium"
              color="text.primary"
              sx={{
                wordBreak: 'keep-all',
                mr: 2
              }}
              flex={2}
              textAlign="right"
            >
              {t('Sample Source')}:
            </Typography>

            <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={sampleField.sample.sampleSource}
                onChange={(e: any) => {
                  setSampleField({
                    sample: {
                      ...sampleField.sample,
                      sampleSource: e.target.value
                    }
                  })

                  props.fn('sample', {
                    ...sampleField.sample,
                    sampleSource: e.target.value
                  })
                }}
                error={Boolean(props.errors.projectId)}
                IconComponent={ExpandCircleDownOutlinedIcon}
                size={'small'}
              >
                {sampleSourceArray.map((sampleSource) => (
                  <MenuItem key={sampleSource.key} value={sampleSource.key}>
                    {sampleSource.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={'flex items-center gap-2'} alignItems="baseline">
            <Typography
              className="font-medium"
              color="text.primary"
              sx={{
                wordBreak: 'keep-all',
                mr: 2
              }}
              flex={2}
              textAlign="right"
            >
              {t('Reference Genome')}
              <span style={{ color: 'red' }}>*</span>:
            </Typography>

            <Box sx={{ flex: isWindow() ? 7 : 5 }}>
              <FormControl fullWidth>
                <Select
                  labelId="select-user-type"
                  value={sampleField.sample.genomeBuild}
                  onChange={(e) => {
                    setSampleField({
                      sample: {
                        ...sampleField.sample,
                        genomeBuild: e.target.value as 'hg19' | 'hg38'
                      }
                    })

                    props.fn('sample', {
                      ...sampleField.sample,
                      genomeBuild: e.target.value
                    })
                  }}
                  IconComponent={ExpandCircleDownOutlinedIcon}
                  size={'small'}
                >
                  {genomeBuildArray.map((genomeBuild) => (
                    <MenuItem key={genomeBuild} value={genomeBuild}>
                      {genomeBuild}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText>
                  <Translations text="Select Genome Build"></Translations>
                </FormHelperText>
              </FormControl>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={'flex items-center gap-2'} alignItems="center">
            <Typography
              className="font-medium"
              color="text.primary"
              sx={{
                wordBreak: 'keep-all',
                mr: 2
              }}
              flex={2}
              textAlign="right"
            >
              {t('project')}:
            </Typography>

            <FormControl fullWidth sx={{ flex: isWindow() ? 7 : 5 }}>
              <Autocomplete
                fullWidth
                value={value}
                onChange={async (event, newValue) => {
                  console.log(newValue)

                  if (typeof newValue !== 'string' && newValue && newValue.id) {
                    setValue(newValue)

                    setSampleField({
                      sample: {
                        ...sampleField.sample,
                        projectId: newValue.id
                      }
                    })

                    props.fn('sample', {
                      ...sampleField.sample,
                      projectId: newValue.id
                    })
                  } else {
                    if (typeof newValue === 'string') {
                      setValue({
                        name: newValue
                      })

                      const params = {
                        name: newValue,
                        isPublic: 0 as 0 | 1,
                        createdAt: new Date(),
                        description: ''
                      }
                      const project = await createProjectMutation.mutateAsync(
                        params
                      )

                      const temp = project.find(
                        (item: any) => item.name === params.name
                      )

                      console.log(project)

                      setSampleField({
                        sample: {
                          ...sampleField.sample,
                          projectId: temp.id
                        }
                      })

                      props.fn('sample', {
                        ...sampleField.sample,
                        projectId: temp.id
                      })
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue({
                        name: newValue.inputValue
                      })
                      const params = {
                        name: newValue.inputValue,
                        isPublic: 0 as 0 | 1,
                        createdAt: new Date(),
                        description: ''
                      }
                      const project = await createProjectMutation.mutateAsync(
                        params
                      )

                      const temp = project.find(
                        (item: any) => item.name === params.name
                      )

                      console.log(project)

                      setSampleField({
                        sample: {
                          ...sampleField.sample,
                          projectId: temp.id
                        }
                      })

                      props.fn('sample', {
                        ...sampleField.sample,
                        projectId: temp.id
                      })
                    } else {
                      setValue(newValue)
                    }
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)

                  const { inputValue } = params

                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  )

                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `添加 "${inputValue}"`
                    })
                  }

                  return filtered
                }}
                size="small"
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={
                  (projects &&
                    projects.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        inputValue: item.name
                      }
                    })) ??
                  []
                }
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option
                  }

                  // Add "xxx" option created dynamically
                  if (option.name) {
                    return option.name
                  }

                  // Regular option
                  return option.name
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.name}</li>
                }}
                freeSolo
                renderInput={(params) => <TextField {...params} />}
              />

              {/* <Controller
                name="projectId"
                control={props.control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <React.Fragment>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={value}
                      autoFocus
                      onChange={(e: any) => {
                        setSampleField({
                          sample: {
                            ...sampleField.sample,
                            projectId: e.target.value
                          }
                        })

                        props.fn('sample', {
                          ...sampleField.sample,
                          projectId: e.target.value
                        })

                        onChange(e)
                      }}
                      onBlur={onBlur}
                      size={'small'}
                      IconComponent={ExpandCircleDownOutlinedIcon}
                      error={Boolean(props.errors.projectId)}
                    >
                      {projects &&
                        projects.map((reg) => (
                          <MenuItem key={reg.id} value={reg.id}>
                            {reg.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </React.Fragment>
                )}
              />

              {props.errors.projectId && (
                <FormHelperText sx={{ color: 'error.main' }}>
                  {props.errors.projectId.message}
                </FormHelperText>
              )}

              <FormHelperText
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main'
                }}
                onClick={() => {
                  setOpenProject(true)
                }}
              >
                {`${t('create')} ${t('project')}`}
              </FormHelperText> */}
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={'flex items-center gap-2'} alignItems="baseline">
            <Typography
              className="font-medium"
              color="text.primary"
              sx={{
                wordBreak: 'keep-all',
                mr: 2
              }}
              flex={2}
              textAlign="right"
            >
              {t('gene')} Panel:
            </Typography>

            <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
              <Controller
                name="genePanel"
                control={props.control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <React.Fragment>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={value}
                      onChange={(e: any) => {
                        if (e.target.value) {
                          setSampleField({
                            sample: {
                              ...sampleField.sample,
                              genePanel: genePanel?.find(
                                (i) => i.panelName === e.target.value
                              )?.id
                            }
                          })

                          props.fn('sample', {
                            ...sampleField.sample,
                            genePanel: genePanel?.find(
                              (i) => i.panelName === e.target.value
                            )?.id
                          })

                          onChange(e)
                        }
                      }}
                      onBlur={onBlur}
                      size={'small'}
                      IconComponent={ExpandCircleDownOutlinedIcon}
                      error={Boolean(props.errors.genePanel)}
                    >
                      {genePanel?.length ? (
                        genePanel?.map((panel) => (
                          <MenuItem
                            key={panel.panelName as string}
                            value={panel.panelName as string}
                          >
                            {panel.panelName}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={''} value={''}>
                          {t('No data available')}
                        </MenuItem>
                      )}
                    </Select>
                  </React.Fragment>
                )}
              />

              {props.errors.genePanel && (
                <FormHelperText sx={{ color: 'error.main' }}>
                  {props.errors.genePanel.message}
                </FormHelperText>
              )}

              <FormHelperText
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main'
                }}
                onClick={() => {
                  setOpenPanel(true)
                }}
              >
                {t('upload')}
                Panel
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={'flex items-center gap-2'} alignItems="baseline">
            <Typography
              className="font-medium"
              color="text.primary"
              sx={{
                wordBreak: 'keep-all',
                mr: 2
              }}
              flex={2}
              textAlign="right"
            >
              {t('Gene Set')}:
            </Typography>

            <FormControl fullWidth sx={{ mb: 4, flex: isWindow() ? 7 : 5 }}>
              <Controller
                name="targetRegionId"
                control={props.control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <React.Fragment>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={value}
                      onChange={(e: any) => {
                        if (e.target.value) {
                          setSampleField({
                            sample: {
                              ...sampleField.sample,
                              targetRegionId: geneSets?.find(
                                (i) => i.name === e.target.value
                              )?.id
                            }
                          })

                          props.fn('sample', {
                            ...sampleField.sample,
                            targetRegionId: geneSets?.find(
                              (i) => i.name === e.target.value
                            )?.id
                          })

                          onChange(e)
                        }
                      }}
                      size={'small'}
                      onBlur={onBlur}
                      IconComponent={ExpandCircleDownOutlinedIcon}
                      error={Boolean(props.errors.targetRegionId)}
                    >
                      {geneSets?.length ? (
                        geneSets?.map((set) => (
                          <MenuItem key={set.name} value={set.name as string}>
                            {set.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={''} value={''}>
                          {t('No data available')}
                        </MenuItem>
                      )}
                    </Select>
                  </React.Fragment>
                )}
              />

              {props.errors.targetRegionId && (
                <FormHelperText sx={{ color: 'error.main' }}>
                  {props.errors.targetRegionId.message}
                </FormHelperText>
              )}

              <FormHelperText
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main'
                }}
                onClick={() => {
                  setOpenRol(true)
                }}
              >
                {t('upload')}ROI
                <span>建议上传或者选择合适的测序厂商BED文件</span>
              </FormHelperText>

              {/* <FormHelperText>
              建议上传或者选择合适的测序厂商BED文件
            </FormHelperText> */}
            </FormControl>
          </Box>
        </Grid>

        <PositionedSnackbar ref={messageRef} />

        <RolDialog
          rolToEdit={null}
          open={openRol}
          onClose={() => {
            setOpenRol(false)
          }}
          onSave={handleSaveGeneSets}
        />

        <PanelDialog
          panelToEdit={null}
          open={openPanel}
          onClose={() => {
            setOpenPanel(false)
          }}
          onSave={handleSaveGenePanel}
          type={'change'}
        />

        <ProjectDialog
          projectToEdit={null}
          open={openProject}
          onClose={() => {
            setOpenProject(false)
          }}
          onSave={handleSaveProject}
        />
      </Grid>
    </React.Fragment>
  )
})

export default StepSample
