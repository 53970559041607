// ** React Imports
import { ChangeEvent, ElementType, useState } from 'react'
import * as XLSX from 'xlsx'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiStep, { StepProps } from '@mui/material/Step'
import CardContent, { CardContentProps } from '@mui/material/CardContent'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Styled Components
import { useMutation } from 'react-query'
import {
  assumeRole,
  createCnvBatch,
  TaskWesCnvBatchDto,
  upload,
  uploadFq
} from '@/@core/services/app.service'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  Radio,
  RadioGroup,
  Stack,
  Tooltip
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getCode } from '@/@core/layouts/utils'
import PositionedSnackbar from '@/@core/components/message'
import React from 'react'
import { DropzoneWrapper } from './DropzoneStyle'
import FileUploaderMultiple from '@/views/forms/file-uploader/FileUploaderMultiple'
import SampleForm from './SampleForm'
import { CheckBold, CloseCircle, TrayArrowUp } from 'mdi-material-ui'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { formatDate, isValidURL, unique } from '@/@core/utils/utils'
import OSS from 'ali-oss'

interface NObject {
  [key: string]: any
}

const ButtonStyled = styled(Button)<
  ButtonProps & { component?: ElementType; htmlFor?: string }
>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center'
  }
}))

const CreateSampleWizard = React.forwardRef((props: any, ref) => {
  const { t } = useTranslation()

  // ** States

  const messageRef = React.useRef<any>()
  const sampleFormRef = React.useRef<any>()
  const [isCnvBatch, setIsCnvBatch] = React.useState(false)
  const [showUpload, setShowUpload] = React.useState<boolean>(false)
  const [data, setData] = React.useState<any[]>([])
  const [files, setFiles] = React.useState<File[]>([])
  const [abortCheckpoint, setAbortCheckpoint] = React.useState<any[]>([])
  const [client, setClient] = React.useState<OSS>()
  const [retryCount, setRetryCount] = React.useState(0)
  const retryCountMax = 1

  const [Key, setKey] = React.useState(0)

  const defaultValues = {
    sample: {
      sampleName: '',
      sampleSource: 'blood',
      type: 'WES',
      genomeBuild: 'hg19' as 'hg19' | 'hg38',
      projectId: null,
      genePanel: null,
      targetRegionId: null,
      prefix: '',
      fileSize: 0,
      hasCnv: false,
      cnvFile: '',
      cnvName: '',
      isFq: false,
      fqFiles: []
    },
    clinical: {
      termList: [],
      clinicalInformation: ''
    },
    patient: {
      patientName: '',
      infirmaryName: '',
      departmentName: '',
      caseNumber: '',
      birthday: null,
      doctor: '',
      gender: 'unknown' as 'male' | 'female' | 'unknown',
      race: null,
      acceptDate: null,
      tooptip: [],
      status: true,
      hasSv: false,
      svFile: '',
      svName: '',
      hasCnv: false,
      cnvFile: '',
      cnvName: '',
      isFq: false,
      fqFiles: []
    }
  }

  const hasCnvBatch = React.useMemo(() => {
    console.log(
      props.samples.map(
        (item: any) =>
          item &&
          item.sampleValue &&
          item.sampleValue.patient &&
          item.sampleValue.patient.fqFiles
      )
    )

    return (
      props.samples.filter(
        (item: any) =>
          item &&
          item.sampleValue &&
          item.sampleValue.patient &&
          item.sampleValue.patient.fqFiles &&
          item.sampleValue.patient.fqFiles.length >= 1
      ).length >= 3
    )
  }, [props.samples])

  const samples2 = React.useMemo(() => {
    return props.samples
  }, [props.samples])

  const getValues = (
    type: 'sample' | 'patient' | 'clinical',
    value: any,
    index: number
  ) => {
    const data = props.samples

    data.map((sample: { sampleValue: any }, i: number) => {
      if (i === index)
        sample.sampleValue = {
          ...sample.sampleValue,
          [type]: value
        }

      return sample
    })

    console.log(data)

    props.setSamples(data)
  }

  const handleUploads = (files: File[]) => {
    console.log(props.samples, files)

    if (files.length) {
      const fqFiles = files.filter(
        (item) =>
          item.name.includes('.fq') ||
          item.name.includes('.fastq') ||
          item.name.includes('.naf')
      )
      const otherFiles = files.filter(
        (item) =>
          !(
            item.name.includes('.fq') ||
            item.name.includes('.fastq') ||
            item.name.includes('.naf')
          )
      )

      console.log(files, fqFiles, sampleFormRef.current?.handleFqUpload)

      if (
        sampleFormRef &&
        sampleFormRef.current &&
        sampleFormRef.current.handleFqUpload
      ) {
        // fq文件上传
        fqFiles.map((file) => {
          sampleFormRef.current.handleFqUpload(file)
        })
      }

      console.log(otherFiles)

      // 普通文件上传

      const data = otherFiles
        .map((file) => {
          if (props.samples.some((item: any) => item.file.name === file.name)) {
            //
          } else return file
        })
        .filter((item) => item)
        .map((file: any, index: number) => {
          const fd = new FormData()
          const fileName = file.name.replace(/\s+/g, '')
          let baseName = fileName.substring(0, fileName.lastIndexOf('.'))
          let isCnv = false

          fd.append('file', file, encodeURI(fileName))

          if (fileName.includes('.cnv')) {
            isCnv = true

            baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
          }

          return {
            file: file,
            progress: 0,
            cnvProgress: 0,
            fileSize: 0,
            isCnv,
            sampleValue: {
              ...defaultValues,
              sample: {
                ...defaultValues.sample,
                sampleName: baseName
              }
            }
          }
        })

      console.log(data)

      if (data && data.length) {
        // props.setSamples([...data, ...props.samples])

        const tempArr = [...data, ...props.samples]

        tempArr.map((items) => {
          if (items.isCnv) {
            props.samples.map((item: any) => {
              if (
                item.sampleValue.sample.sampleName ===
                items.sampleValue.sample.sampleName
              ) {
                item.hasCnv = true

                item.sampleValue.sample.cnvFile = ''

                item.sampleValue.sample.cnvName = `${items.sampleValue.sample.sampleName}.cnv.vcf`
              }

              return item
            })
          }

          return items
        })

        console.log(tempArr)

        handleUpload(otherFiles, tempArr)
      }
    }
  }

  const handleOss = async () => {
    // 向您搭建的STS服务获取临时访问凭证。
    const query = await handleGetAssumeRole.mutateAsync()

    // if (!query || !query.SecurityToken) return
    const client = new OSS({
      // yourRegion填写Bucket所在地域。
      region: 'oss-cn-shenzhen',

      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: query.AccessKeyId,
      accessKeySecret: query.AccessKeySecret,

      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: query.SecurityToken,

      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 200000,

      // 填写Bucket名称。
      bucket: 'glsofort',
      refreshSTSToken: async () => {
        const query = await handleGetAssumeRole.mutateAsync()

        console.log('query', query.Expiration)

        return {
          accessKeyId: query.AccessKeyId,
          accessKeySecret: query.AccessKeySecret,
          stsToken: query.SecurityToken
        }
      }
    })

    setClient(client)

    return client
  }

  const isCredentialsExpired = async (credentials: any) => {
    if (!credentials) {
      return true
    }

    const expireDate = new Date(credentials.Expiration)
    const now = new Date()

    return expireDate.getTime() - now.getTime() <= 60000
  }

  const upFile = async (
    clients: OSS,
    file: File,
    filePath = 'uploads',
    setProcess?: any,
    sampleOption?: {
      type: any
      index: number
      samples: any[]
    },
    option?: OSS.MultipartUploadOptions
  ) => {
    const headers = {
      // 指定该Object被下载时的网页缓存行为。
      'Cache-Control': 'no-cache',

      // 指定该Object被下载时的名称。
      'Content-Disposition': file.name,

      // 指定该Object被下载时的内容编码格式。
      'Content-Encoding': 'utf-8',

      // 指定过期时间，单位为毫秒。
      Expires: '1000',

      // // 指定Object的存储类型。
      // 'x-oss-storage-class': 'Standard',

      // // 指定Object标签，可同时设置多个标签。
      // 'x-oss-tagging': 'Tag1=1&Tag2=2',

      // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
      'x-oss-forbid-overwrite': 'false'
    }
    let options: OSS.MultipartUploadOptions = {
      // 获取分片上传进度、断点和返回值。
      progress: (p: any, cpt: any, res: any) => {
        if (clients) {
          // 获取进度以及上传文件分片
          setProcess(
            p * 100,
            sampleOption?.type,
            sampleOption?.index,
            sampleOption?.samples
          )

          const flag = abortCheckpoint.some(
            (item) => item.name === `${filePath}/${file.name}`
          )

          if (flag)
            abortCheckpoint.map((item) => {
              if (item.name === `${filePath}/${file.name}`) {
                return cpt
              } else return item
            })
          else abortCheckpoint.push(cpt)

          setAbortCheckpoint(abortCheckpoint)
        }
      },

      // 设置并发上传的分片数量。
      parallel: 4,

      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 1024 * 1024,
      headers,
      mime: 'text/plain',
      timeout: 120000 //设置超时时间,
    }
    if (option) options = option

    // const client = await handleOss()

    console.log('client', clients)

    if (clients) {
      const path = `${filePath}/${encodeURI(file.name)}`

      console.log(path)

      // 上传文件
      const data = (await clients
        .multipartUpload(path, file, {
          ...options
        })
        .catch(async (err) => {
          const strErr = JSON.stringify(err)

          console.log(strErr, err)

          console.log('123', client, clients, abortCheckpoint)
          if (strErr.includes('abort')) return

          //更新token
          // const upClient = await handleOss()

          // 判断是否存在分片数据以及重启次数
          const flag = abortCheckpoint.find(
            (item) => item.name === `${filePath}/${file.name}`
          )

          if (flag && retryCount < retryCountMax) {
            let newRetryCount = retryCount

            newRetryCount += 1

            setRetryCount(newRetryCount)

            const option = {
              ...options
            }

            option.checkpoint = flag

            await upFile(
              clients,
              file,
              filePath,
              setProcess,
              sampleOption,
              option
            )
          }
        })) as any

      console.log('data', data)
      const url = data?.res?.requestUrls[0]

      if (url && isValidURL(url)) {
        // setClient(undefined)

        return url
      } else return null
    } else return null
  }

  const handleUpload = async (files: File[], samples: any[]) => {
    console.log('files', files)

    Promise.all(
      files.map(async (file, index) => {
        const fd = new FormData()
        const fileName = file.name.replace(/\s+/g, '')

        fd.append('file', file, encodeURI(fileName))

        // OSS
        const client = await handleOss()

        const fileType = file.name
        const sampleType =
          fileType.includes('.vcf') || fileType.includes('.vcf.gz')
            ? 'vcf'
            : fileType.includes('.gtc')
            ? 'gtc'
            : fileType.includes('.fq.gz') || fileType.includes('.fastq.gz')
            ? 'fastq'
            : fileType.includes('.naf')
            ? 'naf'
            : 'vcf'

        console.log(sampleType)

        if (sampleType === 'fastq' || sampleType === 'naf') {
          const data = await upFile(
            client,
            file,
            'fastq_uploads',
            props.getUploadProgress2,
            {
              type: 'progress',
              index,
              samples
            }
          )

          console.log('datas', data)

          if (data) {
            props.getUploadProgress(data, 'prefix', index, samples)

            props.getUploadProgress(
              Number(file.size / (1024 * 1024)),
              'fileSize',
              index,
              samples
            )

            messageRef?.current?.handleClick(t('Upload successful'), 'success')
          }
        } else {
          const data = await upFile(
            client,
            file,
            'uploads',
            props.getUploadProgress2,
            {
              type: 'progress',
              index,
              samples
            }
          )

          console.log('datas', data)

          if (data) {
            props.getUploadProgress(data, 'prefix', index, samples)

            props.getUploadProgress(
              Number(file.size / (1024 * 1024)),
              'fileSize',
              index,
              samples
            )

            messageRef?.current?.handleClick(t('Upload successful'), 'success')
          }
        }

        // // Api
        // if (sampleType === 'fastq') {
        //   uploadsFq.mutateAsync({
        //     file: fd,
        //     setProgress: (value: any) =>
        //       props.getUploadProgress(value, 'progress', index, samples),
        //     getFileSize: (value: any) =>
        //       props.getUploadProgress(value, 'fileSize', index, samples),
        //     index,
        //     samples
        //   })
        // } else {
        //   uploads.mutateAsync({
        //     file: fd,
        //     setProgress: (value: any) =>
        //       props.getUploadProgress(value, 'progress', index, samples),
        //     getFileSize: (value: any) =>
        //       props.getUploadProgress(value, 'fileSize', index, samples),
        //     index,
        //     samples
        //   })
        // }
      })
    )
  }

  const handleGetAssumeRole = useMutation({
    mutationFn: async () => await assumeRole()
  })

  const uploads = useMutation(
    (data: {
      file: FormData
      setProgress: any
      getFileSize: any
      index: any
      samples: any[]
    }) => {
      return upload(data)
    },
    {
      onSuccess: (data: string, params) => {
        if (data && isValidURL(data)) {
          props.getUploadProgress(data, 'prefix', params.index, params.samples)

          messageRef?.current?.handleClick(t('Upload successful'), 'success')
        }
      },
      onError(error, variables, context) {
        messageRef?.current?.handleClick(
          t('Upload failed') + String(error),
          'error'
        )
      }
    }
  )

  const uploadsFq = useMutation(
    (data: {
      file: FormData
      setProgress: any
      getFileSize: any
      index: any
      samples: any[]
    }) => {
      return uploadFq(data)
    },
    {
      onSuccess: (data: string, params) => {
        if (data && isValidURL(data)) {
          props.getUploadProgress(data, 'prefix', params.index, params.samples)

          messageRef?.current?.handleClick(t('Upload successful'), 'success')
        }
      },
      onError(error, variables, context) {
        messageRef?.current?.handleClick(
          t('Upload failed') + String(error),
          'error'
        )
      }
    }
  )
  const createCnvBatchs = useMutation((data: TaskWesCnvBatchDto) => {
    return createCnvBatch(data)
  })

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '90%',
          mr: 1
        }}
      >
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>

        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    )
  }

  const uploadPatiantInfo = (event: { target: { files: any } }) => {
    const { files } = event.target

    console.log(files)

    if (files && files.length !== 0) {
      const file = event.target.files[0]
      const reader = new FileReader()

      reader.onload = (e) => {
        const data = e.target?.result

        const workbook = XLSX.read(data, {
          type: 'binary',
          cellDates: true,
          dateNF: 'yyyy-mm-dd'
        })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const parsedData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1
        }) as string[][]

        const header = parsedData[0] as string[]

        parsedData.shift()

        const xlsx = parsedData.map((items) => {
          const temp = {} as NObject

          header.map((key, index) => {
            temp[key] = items[index]

            if (key.includes('日期') || key.includes('date')) {
              temp[key] = formatDate(items[index])
            }
          })

          return temp
        })

        console.log(xlsx)

        const sampleData = props.samples.map((items: any, index: number) => {
          const defaultValues = {
            ...items.sampleValue
          }

          for (const key in xlsx[index]) {
            const data = xlsx[index][key]

            console.log(key)

            // patient
            if (key === '地址') {
              defaultValues.patient.address = data
            } else if (key === '城市') {
              defaultValues.patient.city = data
            } else if (key === '出生日期') {
              defaultValues.patient.birthday = data
            } else if (key === '性别') {
              if (data === 'M') defaultValues.patient.gender = 'male'
              else defaultValues.patient.gender = 'female'
            } else if (key === '接收日期') {
              defaultValues.patient.acceptDate = data
            } else if (key === '姓名') {
              defaultValues.patient.patientName = data
            } else if (key === '省份') {
              defaultValues.patient.state = data
            } else if (key === '手机号') {
              defaultValues.patient.phone = data
            } else if (key === 'ZIP') {
              defaultValues.patient.zipCode = data
            } else if (key === '送检日期') {
              defaultValues.patient.dateSent = data
            } else if (key === '临床信息') {
              // defaultValues.patient.phone = data
            } // sample
            else if (key === '样本来源') {
              defaultValues.sample.sampleSource = data.toLowerCase()
            }
          }

          console.log(defaultValues)

          return {
            ...items,
            sampleValue: defaultValues
          }
        })

        console.log(sampleData)

        // props.setSamples(sampleData)

        setData(xlsx)

        setFiles(Array.from(event.target.files))

        // setData(parsedData);
      }

      reader.readAsArrayBuffer(file)
    }
  }

  React.useImperativeHandle(ref, () => {
    return {
      handleUpload,
      createCnvBatchs: createCnvBatchs.mutateAsync,
      isCnvBatch,
      handleFqUpload: sampleFormRef.current?.handleFqUpload
    }
  })

  return (
    <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Stack
            sx={{
              '& img': {
                marginRight: '0 !important'
              }
            }}
          >
            <FileUploaderMultiple
              disableList={true}
              accept=".vcf, .vcf.gz, .gtc, .fq.gz, .fastq.gz,.naf"
              fn={handleUploads}
              key={'upload2'}
            />

            {samples2 &&
              samples2
                .filter((item: { isCnv: any }) => !item.isCnv)
                .map((sample: any, index: number) => {
                  return (
                    <Stack mt={4} key={sample.file.name}>
                      <Card>
                        <CardContent>
                          <Typography>
                            {sample.file.name.replace(/\s+/g, '')}
                          </Typography>

                          <Typography
                            fontSize={12}
                            color={
                              sample.progress === 100
                                ? 'success.main'
                                : 'info.main'
                            }
                          >
                            {sample.progress === 100 ? (
                              <React.Fragment>
                                <CheckBold
                                  sx={{
                                    fontSize: '0.75rem'
                                  }}
                                />
                                Completed
                              </React.Fragment>
                            ) : (
                              'Uploading...'
                            )}
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <LinearProgressWithLabel
                              value={sample.progress}
                              color={
                                sample.progress === 100 ? 'success' : 'info'
                              }
                            />

                            <IconButton
                              onClick={async () => {
                                const data = props.samples.filter(
                                  (item: any) =>
                                    item.sampleValue.sample.sampleName !==
                                    sample.sampleValue.sample.sampleName
                                )

                                const flag = abortCheckpoint.find(
                                  (item) =>
                                    item.name ===
                                      `uploads/${sample.file.name}` ||
                                    item.name ===
                                      `fastq_uploads/${sample.file.name}`
                                )

                                console.log(flag, sample.progress)

                                if (flag && sample.progress !== 100) {
                                  await client?.abortMultipartUpload(
                                    flag?.name,
                                    flag?.uploadId
                                  )

                                  let newAbortCheckpoint = abortCheckpoint

                                  newAbortCheckpoint =
                                    newAbortCheckpoint.filter(
                                      (item) =>
                                        !(
                                          item.name ===
                                            `uploads/${sample.file.name}` ||
                                          item.name ===
                                            `fastq_uploads/${sample.file.name}`
                                        )
                                    )

                                  setAbortCheckpoint(newAbortCheckpoint)

                                  // setClient(undefined)
                                }

                                props.setSamples(data)
                              }}
                            >
                              <CloseCircle fontSize="small" />
                            </IconButton>
                          </Box>

                          {sample.hasCnv ? (
                            <React.Fragment>
                              <Typography>
                                {sample.sampleValue.sample.cnvName.replace(
                                  /\s+/g,
                                  ''
                                )}
                              </Typography>

                              <Typography
                                fontSize={12}
                                color={
                                  sample.cnvProgress === 100
                                    ? 'success.main'
                                    : 'info.main'
                                }
                              >
                                {sample.cnvProgress === 100 ? (
                                  <React.Fragment>
                                    <CheckBold
                                      sx={{
                                        fontSize: '0.75rem'
                                      }}
                                    />
                                    Completed
                                  </React.Fragment>
                                ) : (
                                  'Uploading...'
                                )}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'start'
                                }}
                              >
                                <LinearProgressWithLabel
                                  value={sample.cnvProgress}
                                  color={
                                    sample.cnvProgress === 100
                                      ? 'success'
                                      : 'info'
                                  }
                                />
                              </Box>
                            </React.Fragment>
                          ) : (
                            ''
                          )}

                          {sample &&
                          sample.sampleValue &&
                          sample.sampleValue.patient &&
                          sample.sampleValue.patient.fqFiles &&
                          Array.isArray(sample.sampleValue.patient.fqFiles)
                            ? sample.sampleValue.patient.fqFiles.map(
                                (fq: any) => {
                                  return (
                                    <React.Fragment key={fq.process}>
                                      <Typography>
                                        {fq.fileName.replace(/\s+/g, '')}
                                      </Typography>

                                      <Typography
                                        fontSize={12}
                                        color={
                                          fq.process === 100
                                            ? 'success.main'
                                            : 'info.main'
                                        }
                                      >
                                        {fq.process === 100 ? (
                                          <React.Fragment>
                                            <CheckBold
                                              sx={{
                                                fontSize: '0.75rem'
                                              }}
                                            />
                                            Completed
                                          </React.Fragment>
                                        ) : (
                                          'Uploading...'
                                        )}
                                      </Typography>

                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'start'
                                        }}
                                      >
                                        <LinearProgressWithLabel
                                          value={fq.process}
                                          color={
                                            fq.process === 100
                                              ? 'success'
                                              : 'info'
                                          }
                                        />

                                        <IconButton
                                          onClick={async () => {
                                            sampleFormRef.current &&
                                              sampleFormRef.current
                                                .deleteFqFile &&
                                              sampleFormRef.current.deleteFqFile(
                                                {
                                                  fileName: fq.fileName,
                                                  process: fq.process
                                                }
                                              )
                                          }}
                                        >
                                          <CloseCircle fontSize="small" />
                                        </IconButton>
                                      </Box>
                                    </React.Fragment>
                                  )
                                }
                              )
                            : ''}
                        </CardContent>
                      </Card>
                    </Stack>
                  )
                })}
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box display={'flex'} justifyContent="end" sx={{ m: 2 }}>
            {/* <ButtonStyled
              startIcon={<TrayArrowUp />}
              variant="contained"
              onClick={() => setShowUpload(true)}
            >
              {t('Patient information')}
            </ButtonStyled> */}
            <Tooltip title="目前该点只能识别CSV文件，一键识别患者基本信息，不能一键识别HPO">
              <ButtonStyled
                startIcon={<TrayArrowUp />}
                component="label"
                variant="contained"
                size="small"
              >
                {t('Patient information')}

                <input
                  hidden
                  type="file"
                  onChange={uploadPatiantInfo}
                  accept=".xlsx"
                  id="account-settings-upload-image"
                />
              </ButtonStyled>
            </Tooltip>
          </Box>

          {hasCnvBatch ? (
            <Box py={2} border={'1px solid '} mr={5} pl={2} my={2}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  {t('Run Exome CNV?')}
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={!isCnvBatch ? 'no' : 'yes'}
                  onChange={(e) => {
                    const value = e.target.value
                    if (value === 'yes') {
                      setIsCnvBatch(true)
                    } else setIsCnvBatch(false)
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />

                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Box>
          ) : (
            ''
          )}

          <DropzoneWrapper>
            {/* {showUpload && (
              <Card>
                <Accordion expanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {t('Patients information ')}
                  </AccordionSummary>

                  <AccordionDetails>
                    <Box>
                      <Typography>
                        The patients information template file: Download
                      </Typography>

                      <Stack direction={'row'} spacing={2}>
                        <Box>
                          <ButtonStyled
                            startIcon={<TrayArrowUp />}
                            component="label"
                            variant="contained"
                            size="small"
                          >
                            {t('Select XLSX')}

                            <input
                              hidden
                              type="file"
                              onChange={uploadPatiantInfo}
                              accept=".xlsx"
                              id="account-settings-upload-image"
                            />
                          </ButtonStyled>
                        </Box>

                        <Box>
                          {files.map((file) => {
                            return (
                              <Typography key={file.name}>
                                {file.name}
                              </Typography>
                            )
                          })}
                        </Box>
                      </Stack>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Card>
            )} */}

            {props.samples &&
              props.samples
                .filter((item: { isCnv: any }) => !item.isCnv)
                .map((sample: any, index: number) => {
                  const fileType = sample.file.name
                  const sampleType =
                    fileType.includes('.vcf') || fileType.includes('.vcf.gz')
                      ? 'vcf'
                      : fileType.includes('.gtc')
                      ? 'gtc'
                      : fileType.includes('.fq.gz') ||
                        fileType.includes('.fastq.gz')
                      ? 'fastq'
                      : fileType.includes('.naf')
                      ? 'naf'
                      : 'vcf'

                  return (
                    <Card key={sample.file.name}>
                      <Accordion defaultExpanded={index === 0 ? true : false}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {sample &&
                          sample.sampleValue &&
                          sample.sampleValue.patient &&
                          sample.sampleValue.patient.fqFiles &&
                          Array.isArray(sample.sampleValue.patient.fqFiles)
                            ? `${
                                sample.file.name
                              }/${sample.sampleValue.patient.fqFiles
                                .map((fq: any) =>
                                  fq.fileName.replace(/\s+/g, '')
                                )
                                .join('/')}`
                            : `${sample.file.name}`}
                        </AccordionSummary>

                        <AccordionDetails>
                          <SampleForm
                            sampleType={sampleType}
                            formField={sample.sampleValue}
                            getValues={getValues}
                            index={index}
                            handleUploads={handleUploads}
                            ref={sampleFormRef}
                            setKey={setKey}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Card>
                  )
                })}
          </DropzoneWrapper>
        </Grid>

        <PositionedSnackbar ref={messageRef} />
      </Grid>
    </Card>
  )
})

export default CreateSampleWizard
