import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch
} from '@mui/material'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Translations from '@/layouts/components/Translations'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { fetchProjects } from '@/@core/services/app.service'

const schema = yup.object().shape({
  name: yup.string().min(3).required(),
  description: yup.string()
})

interface Project {
  id?: number
  parentId?: number | null
  name: string
  description: string
  isPublic: 0 | 1
}

interface ProjectDialogProps {
  open: boolean
  onClose: () => void
  projectToEdit: Project | null
  onSave: (project: Project) => void
}

const ProjectDialog: React.FC<ProjectDialogProps> = ({
  open,
  onClose,
  projectToEdit,
  onSave
}) => {
  const defaultValues = {
    name: projectToEdit ? projectToEdit.name : '',
    description: projectToEdit ? projectToEdit.description : '',
    parentId: projectToEdit ? projectToEdit.parentId : undefined,
    isPublic: projectToEdit ? projectToEdit.isPublic : 0
  }
  const [project, setProject] = useState(defaultValues)
  const [isChild, setIsChild] = useState(Boolean(project.parentId))
  const { t } = useTranslation()

  // ** Hooks
  const {
    control: Control,
    handleSubmit: handleSubmit,
    formState: { errors: Errors },
    reset
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  })

  const { data } = useQuery<Project[]>(
    'parentProjects',
    async () => await fetchProjects({ pageNum: 1, pageSize: 1000 }),
    {
      keepPreviousData: true
    }
  )

  const handleSave = () => {
    if (project.name && project.name.length >= 3) {
      if (projectToEdit) {
        // 编辑模式
        onSave({
          ...projectToEdit,
          name: project.name,
          description: project.description,
          isPublic: project.isPublic ? 1 : 0,
          parentId: isChild ? project.parentId : null
        })
      } else {
        // 创建模式
        onSave({
          name: project.name,
          description: project.description,
          isPublic: project.isPublic ? 1 : 0,
          parentId: isChild ? project.parentId : null
        })
      }

      onClose()

      reset(defaultValues)
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle>
          <Translations
            text={projectToEdit ? 'Edit Project' : 'Create Project'}
          ></Translations>
        </DialogTitle>

        <DialogContent dividers>
          <Stack spacing={4}>
            <FormControl fullWidth>
              <Controller
                name="name"
                control={Control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    label={t('projectName')}
                    required
                    sx={{
                      '& .MuiFormLabel-asterisk': {
                        color: 'red'
                      }
                    }}
                    onChange={(event) => {
                      setProject({ ...project, name: event.target.value })

                      onChange(event)
                    }}
                    error={Boolean(Errors.name)}
                    aria-describedby="stepper-linear--name"
                  />
                )}
              />

              {Errors.name && (
                <FormHelperText
                  sx={{ color: 'error.main' }}
                  id="stepper-linear--name"
                >
                  {Errors.name.message}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <Controller
                name="description"
                control={Control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    label={t('projectDescription')}
                    onChange={(event) => {
                      setProject({
                        ...project,
                        description: event.target.value
                      })

                      onChange(event)
                    }}
                    error={Boolean(Errors.description)}
                  />
                )}
              />
            </FormControl>

            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => {
                        setProject({
                          ...project,
                          isPublic: e.target.checked ? 1 : 0
                        })
                      }}
                      checked={Boolean(project.isPublic)}
                      name="gilad"
                    />
                  }
                  label={t('Public or not')}
                />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => {
                        setIsChild(e.target.checked)
                      }}
                      checked={isChild}
                      name="gilad"
                    />
                  }
                  label={t('Child or not')}
                />
              </FormGroup>
            </FormControl>

            {isChild ? (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t('Project')}

                  <span
                    style={{
                      color: 'red'
                    }}
                  >
                    *
                  </span>
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={project.parentId}
                  label={`${t('Project')} *`}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      parentId: e.target.value as number
                    })
                  }
                >
                  {data
                    ?.filter((item) => !item.parentId)
                    ?.filter((item) => item.name !== project.name)
                    .map((items) => {
                      return (
                        <MenuItem key={items.id} value={items.id}>
                          {items.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            ) : (
              ''
            )}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Stack
            width={'100%'}
            alignItems="center"
            justifyContent={'center'}
            direction={'row'}
            spacing={6}
            marginTop={4}
          >
            <Button variant="outlined" onClick={onClose} color="primary">
              <Translations text="cancel"></Translations>
            </Button>

            <Button variant="contained" type="submit" color="primary">
              <Translations text="save"></Translations>
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ProjectDialog
